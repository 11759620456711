import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/sections/banner"
import Blog from "../components/sections/blogs"
import LearnMoreHub from "../components/sections/hubs/learn-more-hub"

import timelineImage from "../images/about-us/timeline.svg";
import timelineImageVertical from "../images/about-us/timeline-vertical.svg";

import dockerLogo from "../images/about-us/contribute-back-technology/docker_logo.svg";
import githubLogo from "../images/about-us/contribute-back-technology/github_logo.svg";
import ripeLogo from "../images/about-us/contribute-back-technology/ripe_logo.svg";

import arn2019 from "../images/about-us/awards/ARN2019.svg";
import businessAward2019 from "../images/about-us/awards/business_award_2019.svg";
import fast100 from "../images/about-us/awards/fast100.svg";
import fastStarter2017 from "../images/about-us/awards/faststarter_2017.svg";
import fastStarter2018 from "../images/about-us/awards/faststarter_2018.svg";
import financialTimesLogo from "../images/about-us/awards/financialtimes_logo.svg";

import ourReachMap from "../images/about-us/ourreach_map.svg";

import bannerImage from "../images/box-headers/about_us.jpg";


import bgImg from "../images/headers/subheader_blue.svg";
import { styleMedia } from "window-or-global";

import {getCategoryId} from "../utils/categories";

const AboutUs = () => {
  const categoryId = getCategoryId("Student Accommodation");
  return (
    <Layout title="About Us">
      <SEO title="About Us - Internet and Software Provider - VostroNet">
        <meta name="description" content="VostroNet is the leading internet infrastructure provider for multi-dwelling developments, delivering high-speed internet through our own fibre network."/>
      </SEO>
      <Banner className="font-white" bgImg={bgImg} data={{
        title: {
          name: "About Us",
          className: "banner-title-sm",
        },
        subtitles: [
          {
            name: `VostroNet is the leading end-to-end internet infrastructure and software provider to multi-dwelling developments`,
            className: "banner-text-small",
          },
        ],
        buttonData: {
          name: "Learn more",
        },
        img: bannerImage,
      }} />
      <AboutSection />
      <OurHistory />
      <ContributeBack />
      <Awards data={[
        {
          title: "Financial Times FT 500: High-Growth Companies Asia-Pacific 2021",
          desc: "VostroNet listed in the Financial Times in the fastest growing companies for 2021 within the Asia-Pacific Region based on multi-year continued performance.",
          img: financialTimesLogo,
        },
        {
          title: "Financial Times FT 500: High-Growth Companies Asia-Pacific 2020",
          desc: "VostroNet listed in the Financial Times in the top 150 fastest growing companies within the Asia-Pacific Region based on multi-year continued performance.",
          img: financialTimesLogo,
        },
        {
          title: "AFR Financial Review Fast 100 2020",
          desc: "The Australian Financial Review recognised VostroNet within the top 100 fastest growing companies in Australia.  The list is compiled annually and independently verified in cooperation with Statista.",
          img: fast100,
        },
        {
          title: "Brisbane Lord Mayor’s Business Awards Winner",
          desc: "In 2019 the CEO of VostroNet, Jonathon Runge, was awarded the prestigious Young Business Person of the Year at Brisbane’s Lord Mayor’s Business Awards. The award recognised the success of our CEO and VostroNet as a multi-national fibre provider and a symbol for entrepreneurship and vision.",
          img: businessAward2019,
          imgClassName: "business-award",
        },
        {
          title: "ARN Innovation Awards 2019 Finalist",
          desc: "In 2019, VostroNet became the finalist in the ARN Innovation Awards for the category of start-up specialist. This award recognises companies that have considerable innovation and have acquired key customer success, acknowledging VostroNet’s innovative technology focus and end-user success in both Australia and overseas.",
          img: arn2019,
        },
        {
          title: "AFR Fast Starters 2018",
          desc: "The Australian Financial Review recognised VostroNet as a ‘Fast Starter’ in the AFR Fast Starters list for 2018. The List is compiled annually of Australia's 100 fastest growing startup businesses.",
          img: fastStarter2018,
        },
        {
          title: "AFR Fast Starters 2017",
          desc: "The Australian Financial Review recognised VostroNet as a ‘Fast Starter’ in the AFR Fast Starters list for 2017. The List is compiled annually of Australia's 100 fastest growing startup businesses.",
          img: fastStarter2017,
        },
      ]} />
      <OurReach />
      <Blog category={categoryId} />
      <LearnMoreHub />
    </Layout>
  );
}

const AboutSection = () => {
  return (
    <div className="general py-5">
      <Container>
        <Row>
          <Col>
            <div className="title mb-3">
              {"Why VostroNet"}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="desc my-3">
              {"VostroNet has rapidly grown to become a leading provider of high-speed internet to residential buildings, student accommodation, hotels, co-working spaces and public spaces across the Asia-Pacific."}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="desc my-3">
              {"VostroNet is an end-to-end provider, deploying our own fibre from the data centre to sites and our own network infrastructure in buildings to deliver high-speed wired and wireless internet to end-users. Our software-defined network is our key technology offering and point-of-difference, allowing us to operate and monitor our network more efficiently and provide a better end-user experience."}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="desc my-3">
              {"Over the last 6 years, we have extensively developed Q2, a fully integrated software platform that manages every aspect of the network: managing network devices and infrastructure, traffic, user management, monitoring & support and billing."}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

const OurHistory = () => {
  return (
    <div className="our-history bg-grey py-5">
      <Container className="general">
        <Row>
          <Col>
            <div className="title mt-3 mb-5">
              {"Our History"}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <object className="timeline-img desktop" data={timelineImage} alt="History Timelime">
              {"vostronet history timeline"}
            </object>
            <object className="timeline-img mobile" data={timelineImageVertical} alt="History Timelime">
              {"vostronet history timeline"}
            </object>
          </Col>
        </Row>
        <Container fluid>
          <Row>
            <Col className="no-padding">
              <Container fluid className="green-callout-group">
                <Row>
                  <Col>
                    <div className="title">
                      {"An Alternative Network Provider"}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="desc">
                      {"We are industry-leaders in software-defined networks, harnessing software to optimise performance and user experience. The entire VostroNet network is monitored and maintained in real-time through our Q2 Platform, resolving issues and keeping our users online."}
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
}

const ContributeBack = () => {
  return (
    <div className="bg-grey contribute">
      <Container className="general">
        <Row>
          <Col>
            <div className="title my-3">
              {"Contributing Back to Technology"}
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="desc my-3">
              {"VostroNet gives back to the community by actively contributing code to projects that are important to the ongoing development of our industry. We strongly believe in contributing to and promoting the start-up community to assist in developing the next generation of businesses and innovators."}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="desc mb-4 bold text-center">
              {"Actively contributing to the start-up and open-source scene"}
            </div>
          </Col>
        </Row>

        <Row className="align-items-center">
          <Col xs={12} lg={4} className="mb-4">
            <div className="contribute-container">
              <a href="https://hub.docker.com/u/vostro" target="_blank" rel="noopener">
                <img src={dockerLogo} alt="docker" className="contribute-img block-center mb-3" />
                <div className="desc text-center contribute-desc">
                  {"Contributing to opensource on Docker Hub"}
                </div>
              </a>
            </div>
          </Col>
          <Col xs={12} lg={4} className="mb-4">
            <div className="contribute-container">
              <a href="https://github.com/VostroNet" target="_blank" rel="noopener">
                <img src={githubLogo} alt="github" className="contribute-img block-center mb-3" />
                <div className="desc text-center contribute-desc">
                  {"Contributing to opensource on GitHub"}
                </div>
              </a>
            </div>
          </Col>
          <Col xs={12} lg={4} className="mb-4">
            <div className="contribute-container">
              <a href="https://atlas.ripe.net/about/" target="_blank" rel="noopener">
                <img src={ripeLogo} alt="ripe" className="contribute-img block-center mb-3" />
                <div className="desc text-center contribute-desc">
                  {"Contributing to global network uptime with Ripe"}
                </div>
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

const Awards = ({ data = [] }) => {
  return (
    <div className="award-section bg-blue py-5">
      <Container className="general">
        <Row>
          <Col>
            <div className="title my-3">
              {"Awards"}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="desc my-3">
              {"VostroNet aims to drastically improve the way the internet is delivered in Australia and New  Zealand and raise users’ expectations for internet provision. We do not compromise on the quality of our network and the way we build it. We have been recognised for our performance in delivering quality internet and our commitment to customer satisfaction."}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="desc my-3">
              {"VostroNet has been recognised as a fast-growing, innovative network company."}
            </div>
          </Col>
        </Row>

        {(data || []).map(({ title = "", desc = "", img, imgClassName }) => {
          return (
            <Row key={title} className="award-info mb-5">
              <Col xs={12} lg={4}>
                <object data={img} className={`block-center award-icon mb-3 ${imgClassName || ""}`}>
                  {title}
                </object>
              </Col>
              <Col xs={12} lg={8}>
                <Row>
                  <Col>
                    <div className="title">{title}</div>
                  </Col>
                </Row>
                <Row>
                  <Col className="award-info">
                    <div className="desc">{desc}</div>
                  </Col>
                </Row>
              </Col>
            </Row>
          );
        })}
      </Container>
    </div>
  );
}

const OurReach = () => {
  return (
    <div className="general bg-grey py-5">
      <Container>
        <Row>
          <Col>
            <div className="title">
              {"Our Reach"}
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs={12} lg={5}>
            <object data={ourReachMap} className="our-reach-map">
              our reach map
            </object>
          </Col>
          <Col xs={12} md={7}>
            <div className="our-reach-desc">
              <div className="desc my-3">
                {"VostroNet owns and maintains an established wholesale fibre network with our reach extending across the Asia-Pacific region. We manage our own network infrastructure on quality equipment, ensuring a great user experience."}
              </div>
              <div className="desc">
                {"Underpinned by world-class technology, we deliver higher speeds, quality and affordability, via our advanced software-defined network"}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default AboutUs;